import React from 'react'
import styles from './style';
import Navbar from './components/Navbar';
import Hero from './components/Hero';

import { Billing, CardDeal, Bussiness, Button, Client, CTA, FeedbackCard, Footer, GetStarted, Stat, Testimonial  } from './components';

const App = () => {
  return (
    <div className="bg-primary w-full overflow-hidden">
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Navbar />
      </div>
    </div>

    <div className={`bg-primary ${styles.flexStart}`}>
      <div className={`${styles.boxWidth}`}>
        <Hero />
      </div>
    </div>
    
    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
       <Stat />
       <Bussiness />
       <Billing />
       <CardDeal />
       <Testimonial />
       <Client />
       <CTA />
       <Footer />
      </div>
    </div>
  </div>
  )
}

export default App;
