import React from 'react'
import { card } from '../assets';
import styles, { layout } from '../style';
import Button from './Button';

const CardDeal = () => {
  return (
    <section className={layout.section}>
    <div className={layout.sectionInfo}>
      <h2 className={styles.heading2}>Find a better card deal<br className='sm:block hidden'/> 
      in a few easy steps.</h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Arcu tortor, purus in mattis at sed integer faucibus. Aliquet quis allquet eget mauris tortor. c Aliquet ultrices ac, ametau.
      </p>
    <Button/>
    </div>

    <div className={layout.sectionImg}>
    <img src={card} className='w-[100%] h-[100%]' alt='cardimg'/>
      
    </div>
    </section>
  )
}

export default CardDeal;
